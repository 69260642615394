











import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import corporateClientModel from "@/api/corporate_client.model";
import InsurerPlanModel from "@/api/insurer_plan.model";
import { ClientKind, translateClientKind } from "@/interfaces/corporate_client";
import EntityForm from "../entity/EntityForm.vue";
import ListPlans from "@/layouts/components/ListPlans.vue";
import servicePlanModel from "@/api/service_plan.model";
import { formatDayMonthYear, isValidCnpj } from "@/helpers/utils";

@Component({
	components: { EntityCrud, EntityForm },
})
export default class CorporateClients extends Vue {
	model: any = corporateClientModel;
	shouldShowExportButton = false;

	title = "Clientes corporativos";
	tableColumns = [{ key: "name", kind: "initialsBadges" }, "personInChargeName", "cnpj"];

	exportColumns = [
		{ key: "name" },
		{
			key: "id",
			name: "ID",
		},
		{ key: "cnpj" },
		{ key: "startDate", onTransform: formatDayMonthYear },
		{ key: "clientKind", onTransform: translateClientKind },
		{
			key: "otherClientKind",
		},
		{ name: "Responsável", key: "personInChargeName" },
		{ name: "Telefone Responsável", key: "personInChargePhoneNumber" },
		{ name: "Telefone Whatsapp", key: "phoneNumber" },
		{ name: "E-mail", key: "personInChargeEmail" },
		{ key: "contractSummary" },
		{ key: "attachmentUrl" },
		{
			key: "address.postCode",
		},
		{ key: "address.street" },
		{ key: "address.number" },
		{ key: "address.complement" },
		{ key: "address.neighborhood" },
		{ key: "address.city" },
		{ key: "address.state" },
		{ key: "insurerPlans", onTransform: this.formatReadOnlyInsurerPlan },
	];

	formColumns = [
		{ key: "name", isReadOnly: false, required: true },
		{
			key: "id",
			isReadOnly: true,
			name: "ID",
			kind: "text",
			validationToShow: () => {
				return this.$route.params.id !== "novo";
			},
		},
		{ key: "cnpj", isReadOnly: false, required: true, validationField: isValidCnpj, messageError: "CNPJ inválido" },
		{ key: "startDate", isReadOnly: false, required: true, onTransform: formatDayMonthYear },
		{ key: "clientKind", isReadOnly: false, onTransform: translateClientKind },
		{
			key: "otherClientKind",
			isReadOnly: false,
			validationToShow: (entity: any) => {
				return entity.clientKind === ClientKind.OTHERS;
			},
		},
		{ name: "Responsável", key: "personInChargeName", isReadOnly: false },
		{ name: "Telefone Responsável", key: "personInChargePhoneNumber", isReadOnly: false },
		{ name: "Telefone Whatsapp", key: "phoneNumber", isReadOnly: false },
		{ name: "E-mail", key: "personInChargeEmail", isReadOnly: false, error: "" },
		{ key: "contractSummary", isReadOnly: false, inputKind: "textarea" },
		{ key: "attachmentUrl", isReadOnly: false },
		{
			key: "address.postCode",
			autoComplete: true,
			autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
		},
		{ key: "address.street", isReadOnly: false },
		{ key: "address.number", isReadOnly: false },
		{ key: "address.complement", isReadOnly: false },
		{ key: "address.neighborhood", isReadOnly: false },
		{ key: "address.city", isReadOnly: false },
		{ key: "address.state", isReadOnly: false },
	];

	plansFormColumns = [
		{ key: "name", isReadOnly: false, required: true },
		{
			key: "id",
			kind: "text",
			isReadOnly: true,
			name: "Id do plano",
			validationToShow: (entity: any) => {
				return !!entity.id;
			},
		},
		{ key: "description", isReadOnly: false },
		{
			key: "emergencyService",
			inputKind: "selectButtons",
			required: true,
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
		},
		{
			key: "emergencyServiceNumber",
			name: "Número do serviço de emergência",
			isReadOnly: false,
			validationToShow: (entity: any) => {
				return entity.emergencyService;
			},
		},
		{ key: "servicePlans", isReadOnly: false, kind: "invisible" },
	];
	tabHeaders = [{ key: "clientKind", kind: "status" }];

	tabs = [
		{
			name: "Informações",
			component: EntityForm,
			model: corporateClientModel,
			formColumns: this.formColumns,
			tabKind: "editionTab",
			isTableAndForm: true,
			formContainerVariant: "primary",
			cleanFormKeys: {
				deleteKeys: ["id", { type: "array", key: "insurerPlans.isLocal" }],
				holdKeys: ["insurerPlans"],
			},
		},
		{
			name: "Plano",
			formStyleVariant: "primary",
			component: ListPlans,
			model: InsurerPlanModel,
			additionalModel: servicePlanModel,
			formColumns: this.plansFormColumns,
			loadMounted: false,
			content: { title: "Informações do plano", alternativeTitle: "Cadastrar plano" },
			kind: "editionForm",
			isTableAndForm: true,
			formContainerVariant: "primary",
			withEntityHeader: true,
			defaultEntity: { servicePlans: [], emergencyService: false },
			buttonText: { text: "Adicionar", alternativeText: "Salvar" },
			onSuccessText: "Os dados foram aplicados.",
			shouldGoToPreviousPageAfterRemove: false,
		},
	];

	formatReadOnlyInsurerPlan(plans: any[]) {
		if (!plans) {
			return;
		}
		const formatPlans = plans?.reduce((stringPlans, currentPlan, indexPlan) => {
			return `${stringPlans} ${currentPlan.name}${indexPlan !== plans.length - 1 && plans.length > 1 ? "," : ""}`;
		}, "");

		return formatPlans;
	}
}
