








































import { tertiaryBody } from "@/helpers/styleClassHelpers";
import { IInsurerPlan } from "@/interfaces/insurer_plan";
import { Component, Prop, Vue } from "vue-property-decorator";
import GuideBalloon from "@/layouts/components/GuideBalloon.vue";

@Component({
	components: {
		GuideBalloon,
	},
})
export default class ListPlans extends Vue {
	@Prop({ default: () => [] }) plans!: IInsurerPlan & { isLocal: boolean }[];

	tertiaryBody = tertiaryBody;

	selectedPlan = {};

	selectPlan(plan: any) {
		this.selectedPlan = plan;
		this.$emit("inputChange", plan);
	}

	newPlan() {
		this.selectedPlan = {};
		this.$emit("inputChange", "novo");
	}

	mounted() {}
}
